// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';

import { Img, Source } from '../components/elements/img';
import { NearestLocationSwitch } from '../components/elements/nearestLocationSwitch';
import { PromoSlide } from '../components/elements/promoSlide';
import { LogoShieldCPA } from '../components/images/logoShieldCPA';
import { LocationLink } from '../components/locationLink';
import { FoldImagesType } from '../pages/[country]';
import { useUserContext } from '../providers/userProvider';
import { getCountryPromos } from '../services/promos';
import {
  Await,
  Country,
  ListResponse,
  LocationWithModulesOptions,
  Promo,
  VenueDetails,
  VenueWithModules,
} from '../services/types';

// eslint-disable-next-line import/no-unresolved
import '@splidejs/react-splide/css';

export type SFPLocation = VenueWithModules<'planvisit'>;
export type SFPLocations = ListResponse<SFPLocation, LocationWithModulesOptions>;

type ContentProps = {
  country: Country;
  location: VenueDetails | VenueWithModules<'planvisit'>;
  locations: ListResponse<VenueWithModules<['planvisit']>, LocationWithModulesOptions>;
  foldImage: FoldImagesType;
  children?: React.ReactNode;
  promos: Await<ReturnType<typeof getCountryPromos>>;
};

const currentHour = Number(moment().format('HH'));

const USAIndex: FC<ContentProps> = ({ country, locations, foldImage, children, promos }) => {
  const { preferredLocation, lastSelectedLocation } = useUserContext();
  const [initVenue, setInitVenue] = useState(
    preferredLocation ? preferredLocation : lastSelectedLocation ? lastSelectedLocation : 0,
  );
  const [displayFoldImage, setDisplayFoldImage] = useState('');
  const [displayFoldClass, setDisplayFoldClass] = useState('');

  useEffect(() => {
    if (currentHour < 19 && foldImage.day.url && foldImage.night.url) {
      setDisplayFoldImage(foldImage.day.url.url);
      setDisplayFoldClass('w-full h-full object-cover object-[82%_25%] lg:object-4/5-center');
    }
    if (currentHour >= 19 && foldImage.day.url && foldImage.night.url) {
      setDisplayFoldImage(foldImage.night.url.url);
      setDisplayFoldClass('w-full h-full object-cover object-[5%_20%]');
    }
  }, [foldImage.day.url, foldImage.night.url]);

  return (
    <>
      <div className="hero">
        <div className="lg:relative bg-white dark:bg-brand-navy lg:overflow-hidden grid grid-cols-2 lg:grid-cols-8 lg:grid-rows-2 gap-4 lg:gap-6 lg:h-[calc(100vh-5rem)] mb-8 lg:mt-6 lg:mr-6">
          <div className="bg-brand col-span-2 lg:col-span-5 lg:row-span-2 relative flex flex-col justify-end h-[50vh] lg:h-auto sm:min-h-[480px]">
            <div className="mx-0 xs:mx-auto sm:mb-[8vw] lg:mb-[12vh] sm:mx-0 relative z-10">
              <div className="inline-block py-4 pl-16 pr-5 ml-4 -mb-4 text-white border-l border-b-3 -rotate-3 sm:rotate-0 sm:bg-gradient-to-r from-brand via-brand bg-brand-bright sm:bg-brand sm:bg-opacity-80 sm:py-8 sm:pl-8 sm:pr-6 lg:pl-16 sm:m-0 border-brand sm:border-none">
                <h1 className="text-2xl font-shield-light-condensed sm:text-10xl leading-1">
                  It’s{' '}
                  <span className="text-accent-neon font-shield-semibold-condensed sm:font-shield-light-condensed">
                    Golf.
                  </span>{' '}
                  It’s{' '}
                  <span className="text-accent-yellow font-shield-semibold-condensed sm:font-shield-light-condensed">
                    Not Golf.
                  </span>{' '}
                  <span className="block tracking-wide sm:tracking-normal leading-none font-shield-semibold-condensed text-9xl sm:text-5.5">
                    It’s Topgolf.
                  </span>
                </h1>
                <div className="block w-20 h-20 sm:w-28 sm:h-28 -rotate-6 sm:hidden absolute top-[9px] sm:top-2 -left-[18px] sm:-left-9 z-0">
                  <LogoShieldCPA />
                </div>
              </div>
              <div className="hidden sm:flex w-[88%] max-w-[520px] 2xl:max-w-xl flex-col items-start justify-center bg-gradient-to-r from-brand-bright via-brand-bright bg-brand-bright bg-opacity-80 text-white py-6 ml-8 lg:ml-16">
                <div className="flex flex-wrap items-center justify-center w-full gap-3 px-1 lg:gap-4">
                  <div className="relative flex flex-col items-center gap-4 text-2xl leading-none uppercase font-shield-black">
                    <div className="absolute -top-12 -left-3 whitespace-nowrap text-3xl -rotate-3 font-shield-semibold-condensed px-2.5 pt-1 pb-1.5 bg-accent-cyan text-brand leading-none">
                      Plan Your Visit
                    </div>
                    <div className="flex items-center gap-1.5 lg:gap-2">
                      <span className="text-base normal-case font-teeline-bold-italic">
                        Find your way to come play
                      </span>
                      <i className="text-base far fa-hand-point-right text-accent-cyan" />
                    </div>
                  </div>
                  <LocationLink
                    slug="plan-a-visit"
                    location={
                      locations?.data[initVenue]?.modules?.planvisit
                        ? locations?.data[initVenue]?.alias
                        : ''
                    }
                    country={country}>
                    <a className="btn white-to-brand" id="home-hero-btn-startplanning-desktop">
                      Start Planning
                    </a>
                  </LocationLink>
                </div>
              </div>
            </div>
            <div className="absolute inset-x-0 top-0 overflow-hidden bottom-16 sm:inset-0 bg-img">
              <Img
                alt="Playing at Topgolf"
                src={displayFoldImage}
                mobile={displayFoldImage}
                className={displayFoldClass}
                loading={'eager'}
                width={1120}
                height={840}
              />
            </div>
            <div className="relative sm:hidden">
              <div className="relative px-2 py-6">
                <div className="relative z-10 flex flex-wrap items-center justify-center gap-3">
                  <div className="flex items-center gap-2">
                    <div className="text-sm leading-none tracking-tight text-center text-white font-teeline-bold-italic">
                      Find your way to play
                    </div>
                    <i className="text-sm far fa-hand-point-right text-accent-cyan" />
                  </div>
                  <div className="">
                    <LocationLink
                      slug="plan-a-visit"
                      location={
                        locations?.data[initVenue]?.modules?.planvisit
                          ? locations?.data[initVenue]?.alias
                          : ''
                      }
                      country={country}>
                      <a
                        className="px-4 py-2 btn white-to-brand !text-xs whitespace-nowrap"
                        id="home-hero-btn-pav-mobile">
                        Plan a visit
                      </a>
                    </LocationLink>
                  </div>
                </div>
                <Img
                  alt="Topgolf mural"
                  aria-hidden="true"
                  src="uploads/images/cp/tg-us-mural-white-trans-UL-1280.png"
                  wrapperClass="block absolute inset-0 bg-brand z-0"
                  className="object-cover object-bottom w-full h-full opacity-10"
                  width={600}
                  height={400}
                />
              </div>
            </div>
          </div>

          <div className="relative lg:col-span-3">
            <Splide
              aria-label="Golf Promos"
              hasTrack={false}
              options={{
                rewind: true,
                type: 'loop',
                autoplay: true,
                pauseOnHover: true,
                pauseOnFocus: true,
                waitForTransition: true,
                interval: 6500,
              }}>
              <SplideTrack>
                {promos.data
                  .filter((promo) => !promo.hide_global && promo.promo_feature == 'feature_a')
                  .slice(0, 3)
                  .map((promo: Promo) => (
                    <SplideSlide className="relative" key={promo.id}>
                      <PromoSlide country={'us'} {...promo} key={promo.id} />
                    </SplideSlide>
                  ))}
              </SplideTrack>
            </Splide>
          </div>
          <div className="relative lg:col-span-3">
            <Splide
              aria-label="Not Golf Promos"
              hasTrack={false}
              options={{
                rewind: true,
                type: 'loop',
                autoplay: true,
                pauseOnHover: true,
                pauseOnFocus: true,
                waitForTransition: true,
                interval: 7000,
              }}>
              <SplideTrack>
                {promos.data
                  .filter((promo) => !promo.hide_global && promo.promo_feature == 'feature_b')
                  .slice(0, 3)
                  .map((promo: Promo) => (
                    <SplideSlide className="relative" key={promo.id}>
                      <PromoSlide country={'us'} {...promo} key={promo.id} />
                    </SplideSlide>
                  ))}
              </SplideTrack>
            </Splide>
          </div>
        </div>
      </div>
      <section>
        <div className="relative">
          <div className="absolute left-0 h-[568px] md:h-full w-full md:w-2/5 bg-brand">
            <div className="absolute inset-0">
              <Img
                alt="Topgolf mural"
                src="uploads/images/cp/tg-us-mural-white-trans-UL-1280.png"
                className="object-cover object-left w-full h-full md:object-2/5-center opacity-10"
                width={1024}
                height={768}
                aria-hidden="true">
                <Source
                  srcSet={'uploads/images/cp/tg-us-mural-white-trans-UL-1280.png'}
                  width={480}
                  height={480}
                  media="(max-width: 768px)"
                />
              </Img>
            </div>
          </div>
          <div className="container relative px-4 pt-4 pb-8 mx-auto overflow-x-hidden md:p-8 lg:p-16 has-dots right top-lg">
            <div className="items-center md:grid md:grid-cols-10">
              <div className="px-4 pt-6 pb-8 md:col-span-3 md:py-16 md:pl-0 md:pr-12">
                <h2 className="text-7xl md:text-3.5 lg:text-4.5 font-shield-semibold-condensed text-white leading-none has-linebreak has-linebreak-cyan">
                  How it Works
                </h2>
                <div className="mt-4 text-white">
                  <p>
                    We’ve got lots of stuff that makes us{' '}
                    <span className="font-teeline-bold-italic text-accent-neon">golf:</span> clubs,
                    balls, tees, turf, a ball-picker-upper-cart thing, etc. We also have lots of
                    stuff that’s{' '}
                    <span className="font-teeline-bold-italic text-accent-yellow whitespace-nowrap">
                      not golf:
                    </span>{' '}
                    loud music, giant targets, giant TVs, and hand-crafted food & drinks.
                  </p>
                  <p className="mb-8">
                    Put all these things under one roof, and you’ve got yourself a good time. So
                    consider this your formal invitation to grab the fam, a few friends, or some
                    coworkers and{' '}
                    <span className="font-teeline-bold-italic">Come Play Around.</span>
                  </p>
                  <LocationLink
                    slug={'experience'}
                    country={country}
                    location={locations?.data[initVenue]?.alias}>
                    <a className="btn sm outline white" id="home-hiw-btn-works">
                      Learn More
                    </a>
                  </LocationLink>
                </div>
              </div>
              <div className="items-start gap-6 md:col-span-7 sm:grid sm:grid-cols-2">
                <div className="relative flex flex-col mb-6 sm:mb-0 bg-white-blue dark:bg-brand-navy-light">
                  <div className="w-full overflow-hidden">
                    <Img
                      alt="Guests in a bay at Topgolf"
                      src="uploads/images/cp/day/tg-cpa-bay-day-adult-group-xnHLtL3-rev.jpg?resize.width=800&resize.height=400&resize.method=cover"
                      wrapperClass="w-full"
                      className="object-cover w-full h-full object-center-1/8"
                      width={800}
                      height={400}
                    />
                  </div>
                  <div className="relative flex-1 px-4 pb-4 md:px-5">
                    <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 -translate-y-full -rotate-4 flex gap-2 items-center justify-center px-3 py-1.5 bg-accent-cyan text-brand leading-none uppercase text-2xl md:text-4xl">
                      <div className="font-shield-light-condensed">Step 1</div>
                    </div>
                    <div className="-mt-4 text-center">
                      <h3 className="inline-block px-4 py-2 text-6xl leading-none text-center uppercase whitespace-nowrap bg-brand-bright text-accent-cyan md:text-4xl lg:text-6xl font-shield-semibold-condensed">
                        Find Your Crew
                      </h3>
                    </div>
                    <p className="mt-4 mb-2 text-sm md:text-center">
                      From a few swings with friends to full-on parties and events, we make it easy
                      to get together with your crew on your terms.{' '}
                      <LocationLink
                        slug={'plan-a-visit'}
                        country={country}
                        location={
                          locations?.data[initVenue]?.modules?.planvisit
                            ? locations?.data[initVenue]?.alias
                            : ''
                        }>
                        <a className="font-teeline-bold-italic">Plan your visit</a>
                      </LocationLink>{' '}
                      or just walk in and set up your tee time.
                    </p>
                  </div>
                </div>
                <div className="relative flex flex-col mb-6 sm:mb-0 bg-white-blue dark:bg-brand-navy-light">
                  <div className="overflow-hidden">
                    <Img
                      alt="Guests checking in at Topgolf"
                      src="uploads/images/cp/playmaker/tg-cpa-playmaker-lobby-checkin-KTp5zGS.jpg?resize.width=800&resize.height=400&resize.method=cover"
                      className="object-cover w-full h-full object-center-1/8"
                      width={800}
                      height={400}
                    />
                  </div>
                  <div className="relative flex-1 px-4 pb-4 md:px-5">
                    <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 -translate-y-full -rotate-4 flex gap-2 items-center justify-center px-3 py-1.5 bg-accent-cyan text-brand leading-none uppercase text-2xl md:text-4xl">
                      <div className="font-shield-light-condensed">Step 2</div>
                    </div>
                    <div className="-mt-4 text-center">
                      <h3 className="inline-block px-4 py-2 text-6xl leading-none text-center uppercase bg-brand-bright text-accent-cyan md:text-4xl lg:text-6xl font-shield-semibold-condensed">
                        Check In
                      </h3>
                    </div>
                    <p className="mt-4 mb-2 text-sm md:text-center">
                      Upon arrival, check in with our front desk team to find the perfect bay for
                      you. Before or after you play, grab{' '}
                      <LocationLink
                        slug={'menu'}
                        country={country}
                        location={locations?.data[initVenue]?.alias}>
                        <a className="font-teeline-bold-italic whitespace-nowrap">
                          food &amp; drink
                        </a>
                      </LocationLink>{' '}
                      in the restaurant and bar or just hang out in our outdoor spaces.
                    </p>
                  </div>
                </div>
                <div className="relative flex flex-col mb-6 sm:mb-0 bg-white-blue dark:bg-brand-navy-light">
                  <div className="overflow-hidden">
                    <Img
                      alt="Guests choosing a Topgolf game"
                      src="uploads/images/cp/playmaker/tg-cpa-playmaker-bay-panel-cb5tTjk.jpg?resize.width=800&resize.height=400&resize.method=cover"
                      className="object-cover w-full h-full object-center-1/8"
                      width={800}
                      height={400}
                    />
                  </div>
                  <div className="relative flex-1 px-4 pb-4 md:px-5">
                    <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 -translate-y-full -rotate-4 flex gap-2 items-center justify-center px-3 py-1.5 bg-accent-cyan text-brand leading-none uppercase text-2xl md:text-4xl">
                      <div className="font-shield-light-condensed">Step 3</div>
                    </div>
                    <div className="-mt-4 text-center">
                      <h3 className="inline-block px-4 py-2 text-6xl leading-none text-center uppercase whitespace-nowrap bg-brand-bright text-accent-cyan md:text-4xl lg:text-6xl font-shield-semibold-condensed">
                        Pick Your Game
                      </h3>
                    </div>
                    <p className="mt-4 mb-2 text-sm md:text-center">
                      Settle into your all-weather hitting bay, and a bay host will help you set up
                      your game and profiles for each player. The fun part is deciding which of{' '}
                      <LocationLink
                        slug={'play/games'}
                        country={country}
                        location={locations?.data[initVenue]?.alias}>
                        <a className="font-teeline-bold-italic whitespace-nowrap">our games</a>
                      </LocationLink>{' '}
                      to play first, with options fun for every skill level.
                    </p>
                  </div>
                </div>
                <div className="relative flex flex-col bg-white-blue dark:bg-brand-navy-light">
                  <div className="overflow-hidden">
                    <Img
                      alt="Guest celebrating a great shot"
                      src="uploads/images/cp/night/tg-cpa-bay-night-adult-group-Kjfkehg.jpg?resize.width=800&resize.height=400&resize.method=cover"
                      className="object-cover w-full h-full object-center-1/8"
                      width={800}
                      height={400}
                    />
                  </div>
                  <div className="relative flex-1 px-4 pb-4 md:px-5">
                    <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 -translate-y-full -rotate-4 flex gap-2 items-center justify-center px-3 py-1.5 bg-accent-cyan text-brand leading-none uppercase text-2xl md:text-4xl">
                      <div className="font-shield-light-condensed">Step 4</div>
                    </div>
                    <div className="-mt-4 text-center">
                      <h3 className="inline-block px-4 py-2 text-6xl leading-none text-center uppercase bg-brand-bright text-accent-cyan md:text-4xl lg:text-6xl font-shield-semibold-condensed">
                        Crush It
                      </h3>
                    </div>
                    <p className="mt-4 mb-2 text-sm md:text-center">
                      Use our free clubs or bring your own, then take aim at the giant outfield
                      targets and our high-tech balls will score themselves. While you play,{' '}
                      <LocationLink slug={'company/app/'} country={country}>
                        <a className="font-teeline-bold-italic">download the Topgolf app</a>
                      </LocationLink>{' '}
                      to enhance your bay experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="">
          <div className="flex flex-col flex-wrap items-center justify-center gap-1 px-4 py-6 mx-auto sm:px-6 sm:py-8 lg:py-12 lg:px-16 lg:flex-row lg:gap-4">
            <div className="flex flex-col items-center justify-center text-2xl leading-none text-center uppercase md:flex-row gap-4 lg:text-left font-shield-black md:text-4xl">
              <span className="block px-4 py-2 leading-none text-10xl lg:text-3 -rotate-3 font-shield-semibold-condensed bg-accent-neon text-brand">
                Ready to play?
              </span>
              <span className="block normal-case font-shield md:inline">
                Find your way to visit
                {locations?.data[initVenue]?.site_id && (
                  <> {locations?.data[initVenue]?.name_short}</>
                )}
                :
              </span>
            </div>
            <div className="flex flex-wrap items-center justify-center gap-4 mt-4 mb-2 lg:m-0">
              {locations?.data[initVenue]?.site_id ? (
                <LocationLink
                  slug="plan-a-visit"
                  location={
                    locations?.data[initVenue]?.modules?.planvisit
                      ? locations?.data[initVenue]?.alias
                      : ''
                  }
                  country={country}>
                  <a className="btn" id="home-hiw-btn-startplanning">
                    Start Planning
                  </a>
                </LocationLink>
              ) : (
                <LocationLink slug="plan-a-visit" country={country}>
                  <a className="btn" id="home-hiw-btn-startplanning">
                    Start Planning
                  </a>
                </LocationLink>
              )}
              <NearestLocationSwitch
                slug={''}
                country={country}
                locations={locations}
                initVenue={initVenue}
                setInitVenue={setInitVenue}
                changeBtnClass={'outline'}
              />
            </div>
          </div>
        </div>
      </section>
      {children}
    </>
  );
};

export default USAIndex;
